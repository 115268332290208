/* For use in src/lib/core/theming/_palette.scss */
$vtg-primary: (
    50: #e0f2f5,
    100: #b3dee5,
    200: #80c9d4,
    300: #66cedb,
    350: #33a9bb,
    400: #00aec3,
    500: #0092a8,
    600: #008aa0,
    700: #007f97,
    800: #00768c,
    900: #00637d,
    950: #015666,
    A100: #aaeaff,
    A200: #77deff,
    A400: #44d1ff,
    A700: #2acbff,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);
$vtg-accent: (
    50: #f6f6f7,
    100: #dedede,
    200: #acacad,
    300: #9b9b9c,
    400: #79797b,
    500: #58585a,
    600: #505052,
    700: #474748,
    800: #3d3d3f,
    900: #2d2d2e,
    A100: #8c8cf3,
    A200: #5e5eef,
    A400: #1a1aff,
    A700: #0101ff,
    A800: #232324,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #fff,
        A400: #fff,
        A700: #fff,
    ),
);
$vtg-warn: (
    50: #fce4e7,
    100: #f7bcc2,
    200: #f28f9a,
    300: #ed6271,
    400: #e94052,
    500: #e51e34,
    600: #e21a2f,
    700: #de1627,
    800: #da1221,
    900: #d30a15,
    A100: #fffdfd,
    A200: #ffcacc,
    A400: #ff979b,
    A700: #ff7e82,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);
