@font-face {
    font-family: DaxPro;
    font-style: normal;
    font-weight: normal;
    src: url('/assets/fonts/DaxPro-Regular.woff') format('woff');
}

@font-face {
    font-family: DaxPro;
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/DaxPro-Medium.woff') format('woff');
}

@font-face {
    font-family: DaxPro;
    font-style: normal;
    font-weight: bold;
    src: url('/assets/fonts/DaxPro-Bold.woff') format('woff');
}
