@use 'node_modules/ag-grid-community/dist/styles/ag-grid.css';
@use 'node_modules/ag-grid-community/dist/styles/ag-theme-material/sass/ag-theme-material-mixin';
@use 'node_modules/ag-grid-community/dist/styles/ag-theme-material/sass/ag-theme-material-font-vars';
@use 'theme';

.ag-theme-material {
    @include ag-theme-material-mixin.ag-theme-material(
        (
            material-primary-color: theme.$color-primary,
            material-accent-color: theme.$color-accent,
            foreground-color: theme.$color-accent-extra-dark,
            background-color: theme.$color-white,
            header-foreground-color: theme.$color-accent-dark,
            header-background-color: theme.$color-accent-extra-light,
            header-cell-hover-background-color: theme.$color-accent-extra-light,
            icon-font-family: 'Material Icons',
            icon-size: 24px,
            card-radius: 4px,
            font-size: 16px,
            grid-size: 8px,
        )
    );

    font-family: DaxPro, 'Helvetica Neue', sans-serif;

    .ag-checkbox-input-wrapper::after {
        color: theme.$color-primary;
    }

    .ag-checkbox-input-wrapper.ag-checked::after {
        color: theme.$color-primary;
    }

    .ag-checkbox-input-wrapper.ag-indeterminate::after {
        color: theme.$color-primary;
    }

    .ag-header-row {
        font-size: 16px;
        font-weight: 500;
    }

    .ag-header-cell {
        padding-left: 12px;
        padding-right: 12px;

        &:hover {
            .ag-header-cell-resize {
                border-left: 2px solid theme.$color-primary;
            }
        }
    }

    .ag-menu {
        z-index: 800 !important;
    }

    .ag-header-cell-sortable {
        cursor: default;
    }

    .ag-cell-wrapper {
        height: 100%;
    }

    .ag-cell {
        padding-left: 11px;
        padding-right: 3px;
    }

    .ag-tooltip {
        @apply bg-primary font-medium text-caption text-white whitespace-pre-line;

        border-radius: 4px;
        text-align: center;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
    }

    .material-icons {
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: theme.$color-primary;
        }
    }

    .ag-header-sticky {
        position: fixed;
        top: 56px;
        z-index: 99999;
    }

    .ag-scrollbar-sticky {
        position: fixed;
        bottom: 0;
    }

    .filter-body-wrapper {
        padding: 16px 16px 2px;
        display: flex;
        flex-direction: column;
    }

    .ag-filter {
        width: 16rem;
    }

    .mat-form-field-appearance-fill {
        .mat-form-field-underline {
            .mat-form-field-ripple {
                background-color: theme.$color-primary;
            }
        }

        &.mat-form-field-invalid {
            .mat-form-field-ripple {
                background-color: theme.$color-error;
            }
        }

        .mat-mdc-form-field-flex {
            background-color: theme.$color-white;
            align-items: center;
            padding: 0;
        }

        .mat-datepicker-toggle {
            color: theme.$color-primary;
        }

        .search-icon {
            color: theme.$color-accent;
            margin-right: 0.25rem;
            height: 24px;
            width: 24px;
        }
    }
}
