@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src:
        local('Material Icons'),
        local('MaterialIcons-Regular'),
        url('/assets/fonts/MaterialIcons-Regular.woff2') format('woff2'),
        url('/assets/fonts/MaterialIcons-Regular.woff') format('woff'),
        url('/assets/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}
