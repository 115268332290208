@use 'theme';

.leaflet {
    @apply h-full w-full;

    .leaflet-div-icon {
        @apply bg-transparent border-none;
    }

    .leaflet-editing-icon {
        @apply bg-primary-dark rounded-full;

        height: 16px !important;
        width: 16px !important;

        &:hover {
            @apply bg-primary-extra-dark;
        }

        // mid-point handle styles, there was no better selector :(
        &[style*='opacity: 0.6'] {
            border: 2px solid theme('colors.white');
            opacity: 1 !important;
            height: 10px !important;
            width: 10px !important;
        }
    }

    .leaflet-draw-guide-dash {
        @apply opacity-100 rounded-full;

        height: 3px;
        width: 3px;
    }

    .leaflet-draw-tooltip {
        @apply bg-primary;

        &::before {
            @apply hidden;
        }

        &.leaflet-error-draw-tooltip {
            @apply bg-error text-white;
        }
    }

    .leaflet-hover-bring-to-front:hover {
        z-index: 900 !important;
    }

    .wagon-marker {
        @apply cursor-pointer flex h-10 items-center justify-center rounded-full w-10;

        @include theme.movement-state-colors;

        border: 3px solid currentcolor;
        color: transparent;

        &.is-selected .wagon-marker__bg {
            @apply text-caution;
        }
    }

    .wagon-marker__bg {
        @apply absolute h-[48px] left-[3px] text-primary top-[3px] w-[40px];

        > path {
            @apply stroke-white stroke-1;
        }
    }

    .wagon-marker__icon {
        @apply relative text-white;

        height: 22px;
        width: 22px;
    }

    .cluster-marker {
        @apply p-2;

        &.is-selected .cluster-marker__chart {
            @apply bg-caution;
        }

        &:hover {
            @apply z-10;
        }
    }

    // show subclusters on cluster hover only if map is not zooming
    .leaflet-map-pane:not(.leaflet-zoom-anim) {
        .cluster-marker:hover {
            .cluster-marker__subcluster {
                @apply flex;
            }
        }
    }

    .cluster-marker__chart {
        @apply bg-primary block cursor-pointer overflow-hidden rounded-full;
    }

    .cluster-marker__subcluster {
        @apply absolute hidden flex items-center justify-center
        h-10 rounded-full text-white w-10
        border border-solid border-white cursor-pointer;

        &.as-idle {
            @apply bg-error;

            bottom: calc(100% - theme('spacing.1'));
            left: calc(50% - theme('spacing.5') - 2px);
        }

        &.as-inactive {
            @apply bg-accent;

            bottom: 61%;
            right: 76%;
        }

        &.as-moving {
            @apply bg-success-dark;

            bottom: 61%;
            left: 76%;
        }
    }

    .leaflet-tooltip {
        @apply bg-primary font-medium px-2 py-1 rounded-md text-caption text-white;
        @apply border-none shadow-none;

        &::before {
            @apply hidden;
        }

        &.as-error {
            @apply bg-error;
        }
    }
}
