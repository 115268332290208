@use '@angular/material' as mat;
@use 'theme';
@use 'mixins';

@tailwind components;
@tailwind utilities;

@layer utilities {
    .auto-cols-max-fr {
        grid-auto-columns: minmax(max-content, 1fr);
    }

    .scrollbar,
    .scrollbar-primary {
        @include mixins.scrollbar-style;
    }

    .scrollbar-primary {
        --scrollbar-base-color: #{mat.m2-get-color-from-palette(
                theme.$vtg-primary,
                900
            )};
        --scrollbar-thumb-color: #{theme.$color-primary};
    }

    // In order to overwrite Material
    .mat-h1.mat-h1,
    .mat-headline-5,
    .text-headline-5,
    .mat-typography.mat-typography h1 {
        @include theme.lt-md-w {
            @apply text-headline-5-sm;
        }

        @include theme.lt-xl-w {
            @apply text-headline-5-md;
        }
    }

    // In order to overwrite Material
    .mat-h2.mat-h2,
    .mat-headline-6 .text-headline-6,
    .mat-typography.mat-typography h2 {
        @include theme.lt-md-w {
            @apply text-headline-6-sm;
        }

        @include theme.lt-xl-w {
            @apply text-headline-6-md;
            @apply mb-8;
        }
    }
}

.container {
    box-sizing: border-box;
}
