@use 'colors';

.mat-table__skeleton {
    .mat-sort-header-arrow {
        opacity: 1 !important;
        transform: translateY(25%) !important;
    }

    .mat-sort-header-sorted .mat-sort-header-arrow {
        color: colors.$color-primary;
    }

    &.mat-table__loading td {
        position: relative;
        color: transparent !important;
    }

    &.mat-table__loading td::after {
        display: inline-block;
        content: '_';
    }

    &.mat-table__loading td::before {
        min-height: 1em;
        content: ' ';
        color: transparent;
        display: inline-block;
        position: absolute;
        height: 24px;
        width: 80%;
        border-radius: 12px;
        background-image: linear-gradient(
            90deg,
            colors.$color-accent-extra-light 0,
            #ddd 40px,
            colors.$color-accent-extra-light 80px
        );
        background-size: 80vw;
        animation: mat-table__skeleton-row--animation infinite linear;
        animation-duration: 1.2s;
    }
}

.skeleton__animation,
.skeleton__placeholder {
    background-image: linear-gradient(
        90deg,
        var(--skeleton-light-color, colors.$color-accent-extra-light) 0,
        var(--skeleton-dark-color, #ddd) 40px,
        var(--skeleton-light-color, colors.$color-accent-extra-light) 80px
    );
    animation: mat-table__skeleton-row--animation infinite linear;
    animation-duration: 1.2s;
}

.skeleton__placeholder {
    animation-duration: 20s;
    background-size: 200px;
    border-radius: 50px;
    color: transparent;
}

@keyframes mat-table__skeleton-row--animation {
    0% {
        background-position: -100px;
    }

    40% {
        background-position: 20vw;
    }

    100% {
        background-position: 60vw;
    }
}
