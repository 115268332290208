$screen-sm-min: 430px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1280px;
$top-menu-height: 56px;

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin lt-sm-w {
    @media (max-width: #{$screen-sm-min - 1px}) {
        @content;
    }
}

@mixin lt-sm-h {
    @media (max-height: #{$screen-sm-min - 1px}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin lt-md-w {
    @media (max-width: #{$screen-md-min - 1px}) {
        @content;
    }
}

@mixin lt-md-h {
    @media (max-height: #{$screen-md-min - 1px}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

@mixin lt-lg-w {
    @media (max-width: #{$screen-lg-min - 1px}) {
        @content;
    }
}

@mixin lt-lg-h {
    @media (max-height: #{$screen-lg-min - 1px}) {
        @content;
    }
}

@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

@mixin lt-xl-w {
    @media (max-width: #{$screen-xl-min - 1px}) {
        @content;
    }
}

@mixin lt-xl-h {
    @media (max-height: #{$screen-xl-min - 1px}) {
        @content;
    }
}

@mixin rwd($screen) {
    @media (min-width: #{$screen}) {
        @content;
    }
}

@mixin lt-rwd-w($screen) {
    @media (max-width: #{($screen) - 1px}) {
        @content;
    }
}

@mixin lt-rwd-h($screen) {
    @media (max-height: #{($screen) - 1px}) {
        @content;
    }
}

@mixin lt-rwd-w-h($width, $height) {
    @media (max-width: #{($width) - 1px}), (max-height: #{($height) - 1px}) {
        @content;
    }
}
