@use '@angular/material' as mat;
@use 'palettes';

/********* PRIMARY *********/
$color-primary-extra-dark: mat.m2-get-color-from-palette(
    palettes.$vtg-primary,
    950
);
$color-primary-dark: mat.m2-get-color-from-palette(palettes.$vtg-primary, 800);
$color-primary: mat.m2-get-color-from-palette(palettes.$vtg-primary, 500);
$color-primary-light: mat.m2-get-color-from-palette(palettes.$vtg-primary, 400);
$color-primary-extra-light: mat.m2-get-color-from-palette(
    palettes.$vtg-primary,
    300
);

/********* ACCENT *********/
/* stylelint-disable value-keyword-case */
$color-accent-extra-dark: mat.m2-get-color-from-palette(
    palettes.$vtg-accent,
    A800
);
$color-accent-dark: mat.m2-get-color-from-palette(palettes.$vtg-accent, 500);
$color-accent: mat.m2-get-color-from-palette(palettes.$vtg-accent, 400);
$color-accent-medium: mat.m2-get-color-from-palette(palettes.$vtg-accent, 300);
$color-accent-light: mat.m2-get-color-from-palette(palettes.$vtg-accent, 100);
$color-accent-extra-light: mat.m2-get-color-from-palette(
    palettes.$vtg-accent,
    50
);

/********* SEMANTIC *********/
$color-error: mat.m2-get-color-from-palette(palettes.$vtg-warn, 500);
$color-error-light: #f08e99;
$color-error-dark: #c01a2c;
$color-caution: #f08827;
$color-caution-dark: #de7f26;
$color-caution-light: #f5bb8a;
$color-success: #74d797;
$color-success-light: #ace7c1;
$color-success-dark: #61ae7c;
$color-white: #ffff;

/********* LANDING PAGE  *********/
$color-peacock: #0056aa;
$color-botticelli: #c7dde5;
$color-alice-blue: #f4f8fa;
$color-white-light: #ffffff1a;
